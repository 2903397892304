import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sistemaConfig from "../../../config/sistemaConfig";

const MenuPesquisa = ({ isDesktop = true }: { isDesktop?: boolean }) => {
  return (
    <InputGroup alignItems="center">
      <InputLeftElement pointerEvents='none' mt={isDesktop ? 2 : 0} pl={isDesktop ? 4 : 0}>
        <FontAwesomeIcon icon={faMagnifyingGlass} fontSize={isDesktop ? "20px" : "16px"} color="gray" />
      </InputLeftElement>
      <Input ringColor={sistemaConfig.siteCorPadrao} borderColor={sistemaConfig.siteCorPadrao} paddingLeft={isDesktop ? "50px" : "35px"} height={isDesktop ? "58px" : "40px"} borderRadius="10px" placeholder={`Buscar produtos no ${sistemaConfig.empresaNome}...`} />
    </InputGroup>
  )
}

export default MenuPesquisa;