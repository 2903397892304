import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import sistemaConfig from "../../../config/sistemaConfig";

interface Item {
  nome: string;
  rota: string;
  ativo?: boolean;
}

interface IProps {
  itens: Item[];
}

const Navigation = ({ itens }: IProps) => {
  const navigate = useNavigate();

  return (
    <Breadcrumb py={3} color={sistemaConfig.siteCorPadrao} style={{
      color: sistemaConfig.siteCorPadrao,
    }} fontWeight='medium' spacing='8px' separator={<FiChevronRight />}>
      {itens && itens.map(item => (
        <BreadcrumbItem key={item.rota} isCurrentPage={item.ativo}>
          <BreadcrumbLink as={item.ativo ? 'b' : 'span'} onClick={() => navigate(item.rota)}>
            {item.nome}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}

export default Navigation;