import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { perfilActions } from "./reducer";
import { formatError } from "../../utils/formatError";
import { DadosPessoais, RedefinirSenha } from "./types";
import { apiAuthCall } from "../sistema/api/apiCall";
import { PayloadAction } from "@reduxjs/toolkit";

function* dadosPessoaisWorker() {
  try {
    const res: AxiosResponse<DadosPessoais> = yield call(apiAuthCall, {
      url: `/loja-virtual/perfil/dados-pessoais`,
      method: "get",
    });
    yield put(perfilActions.dadosPessoaisSuccess(res.data));
  } catch (error) {
    yield put(perfilActions.perfilError(formatError(error)));
  }
}

function* saveDadosPessoaisWorker({ payload }: PayloadAction<DadosPessoais>) {
  try {
    const res: AxiosResponse<DadosPessoais> = yield call(apiAuthCall, {
      url: `/loja-virtual/perfil/dados-pessoais`,
      method: "post",
      data: payload,
    });
    yield put(perfilActions.saveDadosPessoaisRequestSuccess(res.data));
  } catch (error) {
    yield put(perfilActions.perfilError(formatError(error)));
  }
}

function* saveRedefinirSenhaWorker({ payload }: PayloadAction<RedefinirSenha>) {
  try {
    yield call(apiAuthCall, {
      url: `/user`,
      method: "post",
      data: payload,
    });
    yield put(perfilActions.saveRedefinirSenhaRequestSuccess());
  } catch (error) {
    yield put(perfilActions.perfilError(formatError(error)));
  }
}

export function* perfilSaga() {
  yield all([
    takeLatest("perfil/dadosPessoaisRequest", dadosPessoaisWorker),
    takeLatest("perfil/saveDadosPessoaisRequest", saveDadosPessoaisWorker),
    takeLatest("perfil/saveRedefinirSenhaRequest", saveRedefinirSenhaWorker),
  ]);
}
