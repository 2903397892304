import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import MenuPerfilItens from "./MenuPerfilItens";
import MenuPerfilNomeUsuario from "./MenuPerfilNomeUsuario";

interface IProps {
  children: ReactNode;
}

const MenuPerfil = ({ children }: IProps) => {
  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);

  return (
    <Flex
      wrap={isMobile ? 'wrap' : 'nowrap'}
      width="full"
    >
      <Flex
        width={isMobile ? 'full' : '30%'}
        direction="column"
      >
        <MenuPerfilNomeUsuario />
        <MenuPerfilItens />
      </Flex>
      <Flex
        width={isMobile ? 'full' : '70%'}
      >
        {children}
      </Flex>
    </Flex>
  )
}

export default MenuPerfil;