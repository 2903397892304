import { Box, Button, Flex, Heading, Radio, Stack, Text } from "@chakra-ui/react";
import { Form, Formik, useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import sistemaConfig from "../../../config/sistemaConfig";
import { returnOnlyNumber } from "../../../utils/formatString";
import validateForm from "../../../utils/validateForm";
import { Error, InputField, InputRadio, SocialMediaWidget, TextInLine } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../app/mainReducer";
import { sistemaActions } from "../reducer";
import { apiNoAuthCall } from "../api/apiCall";

const Cadastro = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cadastrando = useSelector((state: RootState) => state.sistema.isLoading);
  const auth_token = useSelector((state: RootState) => !!state.sistema.auth_token);
  const errors = useSelector((state: RootState) => state.sistema.errors || []);
  const error = useSelector((state: RootState) => state.sistema.error);

  useEffect(() => {
    if (auth_token) {
      navigate('/')
    }
  }, [navigate, auth_token]);


  const ValidationComponent = () => {
    const formik = useFormikContext();

    useEffect(() => {
      if (typeof errors != "object") {
        return;
      }

      if (!error) return;

      formik.setErrors(errors)

      dispatch(sistemaActions.setSistemaErrorFalse());
    }, [formik, errors, dispatch]);


    return (
      <></>
    );
  };

  interface ICnpjWs {
    razao_social: string;
  }

  const [dadosCnpj, setDadosCnpj] = useState<ICnpjWs | null>(null);

  const consultaCnpj = async (cnpj: string) => {
    cnpj = cnpj.replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '').replaceAll('/', '').replaceAll('\\', '');

    if (cnpj.length < 14) return;

    try {
      const { data } = await apiNoAuthCall({ url: `/loja-virtual/criar-conta/consulta-cnpj/${cnpj}`, method: 'GET' });

      if (!data?.razao_social) return;

      setDadosCnpj(data);
    } catch (err) {
      console.log(err)
    }
  }

  const CnpjComponent = () => {
    const formik = useFormikContext();

    useEffect(() => {
      if (!dadosCnpj) {
        return;
      }

      formik.setValues({
        ...formik.values as any,
        nome: dadosCnpj.razao_social,
      })

      setDadosCnpj(null);
    }, [formik]);

    return (
      <></>
    );
  }

  return (
    <Flex maxW="30rem" minH="100vh" margin="auto" direction="column" px={2}>
      <div style={{ marginTop: 'auto', marginBottom: 'auto', padding: '50px 0px' }}>
        <Flex direction="column">
          <Heading fontSize="40px" size="2xl" mx="auto" mb={10}> Criar conta </Heading>

          <TextInLine text="Acesso rápido" />

          <Flex width="full" wrap="wrap" my={4} justifyContent="center">
            <SocialMediaWidget />
            <SocialMediaWidget />
            <SocialMediaWidget />
          </Flex>

          <TextInLine text="Ou" />

          <Box mt={6}></Box>

          {typeof errors == "string" && <Error error={errors} />}

          <Formik
            initialValues={{ cpf_cnpj: "", tipo_pessoa: "1", nome: "", email: "", celular: "", password: "" }}
            onSubmit={(values, { setErrors }) => {
              values.celular = returnOnlyNumber(values.celular);
              values.cpf_cnpj = returnOnlyNumber(values.cpf_cnpj);

              const errors = validateForm({
                nome: 'required',
                email: 'required',
                celular: 'min|10',
                password: 'min|4',
                cpf_cnpj: values.tipo_pessoa == '1' ? 'min|11' : 'min|14',
              }, values);


              if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
              }

              console.log("cadastro form", values);
              dispatch(sistemaActions.cadastroRequest(values));
            }}
          >
            {({ values }) => (
              <Form>
                <Flex width={"full"} justifyContent="center">
                  <InputRadio onChange={() => values.cpf_cnpj = ''} my={4} size={"lg"} name="tipo_pessoa">
                    <Stack direction="row">
                      <Radio size="lg" value={"1"}>
                        Pessoa física
                      </Radio>
                      <Radio size="lg" value={"2"}>
                        Pessoa jurídica
                      </Radio>
                    </Stack>
                  </InputRadio>
                </Flex>
                <InputField onBlur={() => consultaCnpj(values.cpf_cnpj)} mask={values.tipo_pessoa == '1' ? '999.999.999-99' : '99.999.999/9999-99'} type="tel" placeholder={values.tipo_pessoa == '1' ? 'CPF' : 'CNPJ'} name="cpf_cnpj" />
                <InputField placeholder="Nome" name="nome" autoFocus />
                <InputField placeholder="E-mail" name="email" type="email" />
                <InputField placeholder="Celular" name="celular" type='tel' mask="(99)99999-9999" />
                <InputField placeholder="Senha" name="password" type="password" />
                <ValidationComponent />
                <CnpjComponent />
                <Button
                  mt={6}
                  bg={sistemaConfig.siteCorPadrao}
                  color={"white"}
                  width="full"
                  borderRadius={50}
                  height="55px"
                  _hover={sistemaConfig.siteCorPadrao}
                  fontSize="20px"
                  type="submit"
                  isLoading={cadastrando}
                >
                  Criar conta
                </Button>
              </Form>)}
          </Formik>
        </Flex>
        <Flex mt={2} paddingX={2} fontSize="14px" justifyContent="center" alignItems="center" direction="column">
          Ao criar uma conta você concorda com nossos
          <br />
          <a style={{ color: 'blue', cursor: 'pointer' }} href={sistemaConfig.empresaTermoResponsabilidade} target="_blank" rel="noreferrer">Termos de Uso e Política de Privacidade</a>
          <Text mt={2}>Já tem uma conta? <a onClick={() => navigate('/entrar')} style={{ color: 'blue', cursor: 'pointer' }}><u>Entrar</u></a></Text>
        </Flex>
      </div>
    </Flex >
  )
}

export default Cadastro;