import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { Form, Formik, useFormikContext } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import sistemaConfig from "../../../config/sistemaConfig";
import { returnOnlyNumber } from "../../../utils/formatString";
import validateForm from "../../../utils/validateForm";
import { RootState } from "../../app/mainReducer";
import { Error, InputField, SocialMediaWidget, TextInLine } from "../components";
import { sistemaActions } from "../reducer";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const entrando = useSelector((state: RootState) => state.sistema.isLoading);
  const auth_token = useSelector((state: RootState) => !!state.sistema.auth_token);
  const errors = useSelector((state: RootState) => state.sistema.errors || []);
  const error = useSelector((state: RootState) => state.sistema.error);

  useEffect(() => {
    if (auth_token) {
      navigate('/')
    }
  }, [navigate, auth_token]);


  const ValidationComponent = () => {
    const formik = useFormikContext();

    useEffect(() => {
      if (typeof errors != "object") {
        return;
      }

      if (!error) return;

      formik.setErrors(errors)

      dispatch(sistemaActions.setSistemaErrorFalse());
    }, [formik, errors, dispatch]);


    return (
      <></>
    );
  };

  return (
    <Flex maxW="30rem" minH="100vh" margin="auto" direction="column" px={2}>
      <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
        <Flex direction="column">
          <Heading fontSize="40px" size="2xl" mx="auto" mb={16}> Entrar </Heading>

          <TextInLine text="Acesso rápido" />

          <Flex width="full" wrap="wrap" my={6} justifyContent="center">
            <SocialMediaWidget />
            <SocialMediaWidget />
            <SocialMediaWidget />
          </Flex>

          <TextInLine text="Ou" />

          <Box mt={6}></Box>
          {typeof errors == "string" && <Error error={errors} />}
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values, { setErrors }) => {
              const errors = validateForm({
                email: 'required',
                password: 'required',
              }, values);


              if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
              }

              console.log("login form", values);
              dispatch(sistemaActions.loginRequest(values))
            }}
          >
            <Form>
              <InputField placeholder="E-mail" name="email" autoFocus />
              <InputField placeholder="Senha" name="password" type="password" />
              <ValidationComponent />
              <Button
                mt={6}
                bg={sistemaConfig.siteCorPadrao}
                color={"white"}
                width="full"
                borderRadius={50}
                height="55px"
                _hover={sistemaConfig.siteCorPadrao}
                fontSize="20px"
                type="submit"
                isLoading={entrando}
              >
                Entrar
              </Button>
            </Form>
          </Formik>
        </Flex>
        <Flex mt={2} paddingX={2} fontSize="14px" justifyContent="center" alignItems="center" direction="column">
          Ao entrar você concorda com nossos
          <br />
          <a style={{ color: 'blue', cursor: 'pointer' }} href={sistemaConfig.empresaTermoResponsabilidade} target="_blank" rel="noreferrer">Termos de Uso e Política de Privacidade</a>
          <Text mt={2}>Não tem uma conta? <a onClick={() => navigate('/criar-conta')} style={{ color: 'blue', cursor: 'pointer' }}><u>Cadastre-se</u></a></Text>
        </Flex>
      </div>
    </Flex >
  )
}

export default Login;