import { Flex, Tooltip } from "@chakra-ui/react";
import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import storageConfig from "../../../config/storageConfig";

interface IProps {
  isDesktop?: boolean;
}

const MenuLogo = ({ isDesktop = true }: IProps) => {
  const navigate = useNavigate();

  let style: CSSProperties = {
    height: "35px",
    cursor: 'pointer',
    marginBottom: '5px'
  };

  if (isDesktop) {
    style = {
      height: "70px",
      cursor: 'pointer'
    }

    return (
      <Tooltip onClick={() => navigate('/')} label='Inicio' aria-label='Inicio'>
        <img style={style} src={storageConfig.logoCompleta} alt='logo' />
      </Tooltip>
    )
  }

  return (
    <Flex onClick={() => navigate('/')} alignItems="center" pr={1}>
      <img style={style} src={storageConfig.logoPin} alt='logo' />
    </Flex>
  )
}

export default MenuLogo;