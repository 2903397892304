import { Badge } from "@chakra-ui/react";
import sistemaConfig from "../../../config/sistemaConfig";

const FloatingBadge = ({ number }: { number: number }) => {
  return (
    <Badge
      position="absolute"
      top="5px"
      right="5px"
      borderRadius="full"
      px={2}
      py={0.5}
      color="white"
      bg={sistemaConfig.siteCorPadrao}
    >
      {number}
    </Badge>
  );
}


export default FloatingBadge;