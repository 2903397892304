import { Box, CircularProgress, Flex, Text } from "@chakra-ui/react";
import sistemaConfig from "../../../config/sistemaConfig";

const Loader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <>{isLoading && <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex="9999"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(0, 0, 0, 0.7)"
    >
      <Flex direction='column' alignItems={"center"}>
        <CircularProgress isIndeterminate color={sistemaConfig.siteCorPadrao} style={{
            color: sistemaConfig.siteCorPadrao,
          }} />
        <Text color="white" mt={4}>
          Carregando...
        </Text>
      </Flex>
    </Box>}
    </>
  );
};

export default Loader;