import { Box, Container, Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import MenuItens from "./MenuItens";
import MenuLogo from "./MenuLogo";
import MenuPesquisa from "./MenuPesquisa";

interface IProps {
  children: ReactNode;
}

const Menu = ({ children }: IProps) => {
  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);

  return (
    <Flex width="full" direction="column">
      <Flex
        position="sticky"
        top={0}
        zIndex={999}
        width="full"
        wrap="wrap"
        direction="inherit"
        alignItems="center"
        boxShadow="0px 4px 50px -13px rgba(0, 0, 0, 0.25)"
        borderRadius="0px 0px 20px 20px"
        bg="white"
      >
        {!isMobile && <Flex width='1366px' wrap="wrap" alignItems="center">
          <Box width="15%" py={2}>
            <MenuLogo />
          </Box>
          <Box width="65%" py={2}>
            <MenuPesquisa />
          </Box>
          <Box width="20%">
            <MenuItens isDesktop={true} />
          </Box>
        </Flex>}
        {isMobile && <Flex width={'100%'} wrap="wrap" py={2} alignItems="center">
          <Flex width="full" px={2}>
            <MenuLogo isDesktop={false} />
            <MenuPesquisa isDesktop={false} />  
          </Flex>
        </Flex>}
      </Flex>

      <Container as="main" p={2} mb={16} maxW="1366px">
        {children}
      </Container>

      {isMobile && <Box
        as="footer"
        position="fixed"
        bottom="0"
        width="100%"
        bg="white"
        borderRadius="20px 20px 0px 0px"
        boxShadow="0px 4px 50px -13px rgba(0, 0, 0, 0.25)"
      >
        <MenuItens showHome={true} isDesktop={false} />
      </Box>}
    </Flex>
  )
}

export default Menu;