import { Box, Divider, Text } from "@chakra-ui/react"

interface IProps {
  text: string;
}

const TextInLine = ({ text }: IProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Divider height="2px" bg="#D9D9D9" flex="1" />
      <Text fontWeight="thin" opacity="70%" mx="2" fontSize={"12px"}>{text}</Text>
      <Divider height="2px" bg="#D9D9D9" flex="1" />
    </Box>
  )
}

export default TextInLine;