import { Flex } from "@chakra-ui/react"

const SocialMediaWidget = () => {
  return (
    <Flex mx={2} bg="#D9D9D9" width="45px" height="45px" borderRadius={10} boxShadow="box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);">

    </Flex>
  )
}


export default SocialMediaWidget;