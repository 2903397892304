import { Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { Error, Loader, Menu, Navigation } from "../../sistema/components"
import { MenuPerfil, MenuPerfilHeader } from "../components"

const MeusPedidos = () => {
  const isLoading = useSelector((state: RootState) => state.perfil.isLoading);
  const isSaving = useSelector((state: RootState) => state.perfil.isSaving);
  const errors = useSelector((state: RootState) => state.perfil.errors || []);
  const error = useSelector((state: RootState) => state.perfil.error);

  return (
    <Menu>
      <Navigation
        itens={[
          {
            nome: 'Início',
            rota: '/',
          },
          {
            nome: 'Perfil',
            rota: '/dados-pessoais',
          },
          {
            nome: 'Meus Pedidos',
            rota: '/meus-pedidos',
            ativo: true,
          },
        ]}
      />
      <MenuPerfil>
        <Loader isLoading={isLoading ?? false} />
        <Flex wrap="wrap" width="full">
          <MenuPerfilHeader nome="Meus Pedidos" />

          {typeof errors == "string" && <Error error={errors} />}
        </Flex>
      </MenuPerfil>
    </Menu>
  )
}

export default MeusPedidos;