import { Routes, Route } from "react-router-dom";
import { useIsMobile } from "../../hooks";
import { DadosPessoais, MeusPedidos } from "../perfil/views";
import { Cadastro, Home, Login, Sair } from "../sistema/views";

const Router: React.FC<{}> = () => {
  useIsMobile();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/criar-conta" element={<Cadastro />} />
      <Route path="/entrar" element={<Login />} />
      <Route path="/dados-pessoais" element={<DadosPessoais />} />
      <Route path="/meus-pedidos" element={<MeusPedidos />} />
      <Route path="/sair" element={<Sair />} />
    </Routes>
  );
};

export default Router;