import { Flex } from "@chakra-ui/react";
import MenuItem from "./MenuItem";
import { FiHeart, FiHome } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { FiShoppingCart } from "react-icons/fi";
import { useLocation } from "react-router-dom";

interface IProps {
  showHome?: boolean;
  isDesktop?: boolean;
}

const isRotaAtiva = (rotas: string[], pathname: string, equal = false) => {
  return rotas.some(function (rota) {
    if (equal) {
      return rota === pathname;
    }
    return pathname.includes(rota);
  });
}

const quantidadeItemCarrinho = () => {
  return 0;
}

const quantidadeItemFavoritos = () => {
  return 0;
}

const MenuItens = ({ showHome = false, isDesktop = true }: IProps) => {
  const location = useLocation();

  return (
    <Flex width="full" wrap="wrap" justifyContent="space-between" px={8} color="black">
      {showHome && <MenuItem routeTo="/" isDesktop={isDesktop} icon={<FiHome fontSize="25px" />} rotaAtiva={isRotaAtiva(['/'], location.pathname, true)} nome="Produtos" />}
      <MenuItem routeTo="/dados-pessoais" isDesktop={isDesktop} icon={<FiUser fontSize="25px" />} rotaAtiva={isRotaAtiva(['/dados-pessoais', '/meus-pedidos'], location.pathname)} nome="Perfil" />
      <MenuItem isDesktop={isDesktop} icon={<FiHeart fontSize="25px" />} rotaAtiva={isRotaAtiva(['/favoritos'], location.pathname,)} nome="Favoritos" badgeNumber={quantidadeItemFavoritos()} />
      <MenuItem isDesktop={isDesktop} icon={<FiShoppingCart fontSize="25px" />} rotaAtiva={isRotaAtiva(['/carrinho'], location.pathname)} nome="Carrinho" badgeNumber={quantidadeItemCarrinho()} />
    </Flex>
  )
}

export default MenuItens;