export const formatError = (error: any) => {
  if (error?.response?.data?.errors) {
    let retorno = {};

    for (const item of Object.keys(error.response.data.errors)) {
      retorno = {
        ...retorno,
        [item]: error.response.data.errors[item][0],
      }
    }
    return retorno;
  }

  if (error?.response?.data?.error) {
    return error?.response?.data?.error;
  }

  return error?.response?.data?.message
    ? error.response?.data?.message
    : JSON.stringify(error)
}