import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import { Form, Formik, useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { FiEdit, FiTrash } from "react-icons/fi";
import { useSelector } from "react-redux";
import sistemaConfig from "../../../config/sistemaConfig";
import { returnOnlyNumber } from "../../../utils/formatString";
import validateForm from "../../../utils/validateForm";
import { RootState } from "../../app/mainReducer";
import { ConfirmationModal, InputCheck, InputField, ListHeaderSecondary } from "../../sistema/components";
import { novoEndereco } from "../data";
import { DadosPessoais, Endereco } from "../types";
import MenuPerfilHeader from "./MenuPerfilHeader";

interface IProps {
  values: DadosPessoais;
  forceSubmit: () => void;
}

const MenuPerfilEnderecos = ({ values, forceSubmit }: IProps) => {
  const [{ onChange, value }, { error, touched }, { setValue }] = useField({ name: 'parceiro.enderecos' });
  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);
  const wMd = isMobile ? '100%' : "80%";
  const wMdAddress = isMobile ? '100%' : "20%";

  interface DadosCep {
    bairro: string;
    complemento: string;
    ibge: string;
    localidade: string;
    uf: string;
  }


  const [dadosCep, setDadosCep] = useState<DadosCep | null>(null);

  const consultaCep = async (cep: string) => {
    cep = cep.replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '');
    console.log(cep)
    if (cep.length < 8) return;

    const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

    if (!data?.localidade) return;

    setDadosCep(data);
  }

  const CepComponent = () => {
    const formik = useFormikContext();

    const values: Endereco = formik.values as Endereco;

    useEffect(() => {
      if (!dadosCep) {
        return;
      }

      formik.setValues({
        ...values as any,
        cep: {
          ...values.cep,
          cidade: {
            ...values.cep.cidade,
            nome: dadosCep.localidade,
            uf: {
              ...values.cep.cidade.uf,
              uf: dadosCep.uf,
            }
          },
        }
      })

      setDadosCep(null);
    }, [formik, values]);

    return (
      <></>
    );
  };

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentItem, setCurrentItem] = useState<Endereco | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onOpenModal = (index: string | number) => {
    if (index === 'novo') {
      setCurrentIndex(-1)
      setCurrentItem(novoEndereco())
      onOpen()
      return;
    }

    setCurrentIndex(index as number);
    setCurrentItem(value[index]);
    onOpen()
  }

  const onSaveModal = (form: Endereco) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    form = {
      ...form,
      changed: true,
    }

    if (currentIndex === -1) {
      const valuesToPush = [
        ...values,
        ...valuesDeleted,
      ]
      valuesToPush.push(form);
      setValue(valuesToPush)
      onClose();
      setCurrentItem(null);
      forceSubmit()
      return;
    }

    values[currentIndex] = {
      ...values[currentIndex],
      ...form,
    };
    setValue([
      ...values,
      ...valuesDeleted,
    ])
    onClose();
    setCurrentItem(null);
    setCurrentIndex(-1);
    forceSubmit()
  }

  const onDeleteItem = (index: number) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setValue([
        ...values,
        ...valuesDeleted,
      ])
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setValue([
      ...values,
      ...valuesDeleted,
    ])
  }

  return (
    <>
      <MenuPerfilHeader nome={values.parceiro.tipo_pessoa == '1' ? 'Endereços' : 'Endereço'} showBack={false}>
        {values.parceiro.tipo_pessoa == '1' && <Button
          bg={sistemaConfig.siteCorPadrao}
          color={"white"}
          borderRadius={5}
          height="30px"
          _hover={sistemaConfig.siteCorPadrao}
          fontSize="14px"
          mb={2}
          onClick={() => onOpenModal('novo')}
        >
          Novo endereço
        </Button>}
      </MenuPerfilHeader>
      <Flex width="full" wrap={"wrap"}>
        {values.parceiro.tipo_pessoa == '2' &&
          <>
            <InputField disabled height="42px" label="Endereço" name="parceiro.endereco" width={wMd} />
            <InputField disabled height="42px" label="Número" name="parceiro.numero" width={wMdAddress} />
            <InputField disabled height="42px" label="Bairro" name="parceiro.bairro" width={wMdAddress} />
            <InputField disabled height="42px" label="Complemento" name="parceiro.complemento" width={wMdAddress} />
            <InputField disabled height="42px" label="Cep" name="parceiro.cep.numero" width={wMdAddress} />
            <InputField disabled height="42px" label="Cidade" name={"parceiro.cep.cidade.nome"} width={wMdAddress} />
            <InputField disabled height="42px" label="UF" name="parceiro.cep.cidade.uf.uf" width={wMdAddress} />
          </>
        }
        {values.parceiro.tipo_pessoa == '1' &&
          <Flex width={"full"} direction={"column"}>
            {values.parceiro.enderecos.map((i, index) => (
              <Box mb={2} position="relative" p={4} key={`${index}`} width="full" boxShadow="10px 10px 10px -13px rgba(0, 0, 0, 0.25)" borderRadius={"20px 20px 20px 20px"} bgColor="#EFEDED">
                <Text fontSize={"20px"} width="80%" mb={2}>{i.logradouro}, n° {i.numero}</Text>
                <small>
                  {i.complemento && <>Complemento: {i.complemento} <br /></>}
                  Bairro: {i.bairro} <br />
                  CEP {i.cep.numero} - {i.cep.cidade.nome} - {i.cep.cidade.uf.uf} <br />
                  {i.endereco_padrao == '1' && <b>Endereço padrão</b>}
                </small>
                <Tooltip label="Editar endereço">
                  <Flex onClick={() => { onOpenModal(index); }} position={"absolute"} top={3} right={3} padding={2} borderRadius={"50%"} _hover={{ backgroundColor: '#d7d5d5' }} cursor="pointer">
                    <FiEdit />
                  </Flex>
                </Tooltip>

                <ConfirmationModal confirm={() => onDeleteItem(index)}>
                  <Tooltip label="Apagar endereço">
                    <Flex position={"absolute"} bottom={3} right={3} padding={2} borderRadius={"50%"} _hover={{ backgroundColor: '#d7d5d5' }} cursor="pointer">
                      <FiTrash />
                    </Flex>
                  </Tooltip>
                </ConfirmationModal>

              </Box>
            ))}
          </Flex>}
      </Flex>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={currentItem?.ukey ? 'Editar endereço' : 'Novo endereço'} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentItem && <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={(val, { setErrors }) => {
                const validation = validateForm({ logradouro: 'required', numero: 'required', bairro: 'required' }, val)
                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                if (returnOnlyNumber(val.cep.numero ?? '').length < 8) {
                  setErrors({
                    cep: {
                      numero: 'Preencha corretamente o CEP'
                    }
                  })
                  return
                }

                onSaveModal({
                  ...val,
                  cep: {
                    ...val.cep,
                    numero: returnOnlyNumber(val.cep.numero)
                  },
                  changed: true,
                });
              }}
            >
              {({ values }) => (
                <Form style={{
                  maxHeight: "calc(100vh - 220px)",
                  overflowY: 'auto'
                }}>
                  <Flex width="full" wrap="wrap">
                    <InputField height="42px" mask="99.999-999" placeholder="Digite o CEP aqui" onBlur={() => consultaCep(values.cep.numero)} label="CEP" name="cep.numero" width={isMobile ? '100%' : "40%"} />
                    <InputField height="42px" placeholder="Preencha o CEP" disabled label="Cidade" name="cep.cidade.nome" width={isMobile ? '80%' : "40%"} />
                    <InputField height="42px" placeholder="Preencha o CEP" disabled label="UF" name="cep.cidade.uf.uf" width={"20%"} />
                    <InputField height="42px" placeholder="Escreva o endereço aqui" label="Endereço" name="logradouro" width={isMobile ? '70%' : "80%"} />
                    <InputField height="42px" placeholder="Número" label="Número" name="numero" width={isMobile ? '30%' : "20%"} />
                    <InputField height="42px" placeholder="Bairro" label="Bairro" name="bairro" width={isMobile ? '100%' : "50%"} />
                    <InputField height="42px" placeholder="Complemento (Opcional)" label="Complemento (Opcional)" name="complemento" width={isMobile ? '100%' : "50%"} />
                    <InputCheck label="Endereço padrão das entregas?" name="endereco_padrao" />

                    <CepComponent />

                    <hr style={{ width: '100%', margin: '10px 0' }} />

                    <Button
                      bg={sistemaConfig.siteCorPadrao}
                      color={"white"}
                      borderRadius={5}
                      _hover={sistemaConfig.siteCorPadrao}
                      mt={2}
                      type="submit"
                    >
                      Salvar endereço
                    </Button>
                    <Button onClick={onClose} ml={2} mt={2} colorScheme="blackAlpha">Fechar</Button>
                  </Flex>
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MenuPerfilEnderecos;
