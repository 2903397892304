import { Flex, Heading, Text } from "@chakra-ui/react"
import { ReactNode } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface IProps {
  nome: string;
  voltarPara?: string;
  showBack?: boolean;
  children?: ReactNode;
}

const MenuPerfilHeader = ({ nome, voltarPara = "/", showBack = true, children }: IProps) => {
  const navigate = useNavigate();

  return (
    <Flex direction="column" width={"full"}>
      {showBack && <Flex onClick={() => navigate(voltarPara)} cursor="pointer" fontSize="18px" alignItems="center" my={4}>
        <FiArrowLeft /> &nbsp; <Text fontWeight={100}>VOLTAR</Text>
      </Flex>}
      <Flex my={!showBack ? 4 : 0}>
        <Heading color="#444444" fontSize="35px" size="2xl" my={4}>{nome} {children}</Heading>
      </Flex>
    </Flex>
  )
}

export default MenuPerfilHeader;