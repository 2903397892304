import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import ReactInputMask from "react-input-mask";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  textarea?: boolean;
  mask?: string;
  bgNone?: boolean;
  onChange?: () => void;
  onBlur?: () => void;
  rows?: number;
  height?: string;
  padding?: number;
};

const InputField: React.FC<InputFieldProps> = ({
  label,
  disabled,
  mask,
  textarea,
  bgNone = false,
  rows = 1,
  height = "50px",
  padding = 1,
  onBlur,
  ...props
}) => {
  const [field, { error, touched }] = useField(props);

  if (mask && mask.length > 1) {
    return (
      <FormControl width={props.width ? props.width : '100%'} padding={padding} isInvalid={touched && error ? true : false} mt={label ? 0 : 2}>
        {label && <FormLabel mb={0} mt={2}>{label}</FormLabel>}
        <Input
          borderColor="#C3C3C3"
          borderRadius="8px"
          height={height}
          fontSize="18px"
          bg={!bgNone ? 'white' : 'none'}
          as={ReactInputMask}
          mask={mask}
          disabled={disabled}
          type={props.type}
          placeholder={props.placeholder}
          {...field}
          isInvalid={touched && error ? true : false}
          onBlur={() => typeof onBlur === 'function' && onBlur()}
        />
        {error && <FormErrorMessage mt={1}>{error}</FormErrorMessage>}
      </FormControl>
    );
  }
  if (textarea) {
    return (
      <FormControl padding={padding}>
        {label && <FormLabel mb={0} mt={2}>{label}</FormLabel>}
        <Textarea
          rows={rows}
          bg={!bgNone ? 'white' : 'none'}
          disabled={disabled}
          {...field}
          isInvalid={touched && error ? true : false}
          placeholder={props.placeholder}
        ></Textarea>
        {touched && error && <Text color="red">{error}</Text>}
      </FormControl>
    );
  }

  return (
    <FormControl
      isInvalid={touched && error ? true : false}
      mt={label ? 0 : 2}
      width={props.width ? props.width : '100%'}
      padding={padding}
    >
      {label && <FormLabel
        mb={0}
        mt={2}
      >
        {label}
      </FormLabel>}
      <Input
        borderColor="#C3C3C3"
        borderRadius="8px"
        height={height}
        fontSize="18px"
        bg={!bgNone ? 'none' : 'none'}
        disabled={disabled}
        type={props.type}
        placeholder={props.placeholder}
        {...field}
        isInvalid={touched && error ? true : false}
        onBlur={() => typeof onBlur === 'function' && onBlur()}
      />
      {error && <FormErrorMessage mt={1}>{error}</FormErrorMessage>}
    </FormControl>
  );
};


export default InputField;