import { Cep, Endereco } from "../types";

const novoEndereco = (): Endereco => {
  return {
    logradouro: "",
    numero: "",
    bairro: "",
    complemento: "",
    a25_ukey: "",
    endereco_padrao: "1",
    cep: {
      numero: "",
      cidade: {
        nome: "",
        uf: {
          uf: "",
        },
      },
    } as Cep,
  }
}

export default novoEndereco;