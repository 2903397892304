import { Box, Flex, Text } from "@chakra-ui/react";
import { FiUser } from "react-icons/fi";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";

const MenuPerfilNomeUsuario = () => {
  const usuario = useSelector((state: RootState) => state.sistema.user);

  return (
    <Flex wrap="wrap">
      <Box>
        <FiUser size="75px" color="#444444" />
      </Box>
      <Flex fontSize="18px" color="#444444" justifyContent={"center"} direction="column" pl={4}>
        <Text>Olá,</Text>
        <Text><b>{usuario?.nome}!</b></Text>
      </Flex>
    </Flex>
  )
}

export default MenuPerfilNomeUsuario;