import { Button, Divider, Flex, Radio, Skeleton, Stack } from "@chakra-ui/react";
import { Formik, useFormikContext, Form } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import sistemaConfig from "../../../config/sistemaConfig";
import { useIsAuth } from "../../../hooks";
import { returnOnlyNumber } from "../../../utils/formatString";
import validateForm from "../../../utils/validateForm";
import { RootState } from "../../app/mainReducer";
import { Error, InputField, InputRadio, Loader, Menu, Navigation } from "../../sistema/components";
import { MenuPerfil, MenuPerfilEnderecos, MenuPerfilHeader } from "../components";
import { perfilActions } from "../reducer";
import { DadosPessoais as DadosPessoaisType } from "../types";

const DadosPessoais = () => {
  useIsAuth();
  const dispatch = useDispatch();
  const dadosPessoais = useSelector((state: RootState) => state.perfil.dados_pessoais);
  const isLoading = useSelector((state: RootState) => state.perfil.isLoading);
  const isSaving = useSelector((state: RootState) => state.perfil.isSaving);
  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);

  useEffect(() => {
    dispatch(perfilActions.dadosPessoaisRequest());
  }, [dispatch])

  const errors = useSelector((state: RootState) => state.perfil.errors || []);
  const error = useSelector((state: RootState) => state.perfil.error);

  const ValidationComponent = () => {
    const formik = useFormikContext();

    useEffect(() => {
      if (typeof errors != "object") {
        return;
      }

      if (!error) return;

      formik.setErrors(errors)

      dispatch(perfilActions.setPerfilErrorFalse());
    }, [formik, errors, dispatch]);

    return (
      <></>
    );
  };

  const CpfCnpjComponent = () => {
    const formik = useFormikContext();
    const tipo_pessoa = (formik.values as DadosPessoaisType).parceiro.tipo_pessoa


    return (
      <InputField disabled height="42px" mask={tipo_pessoa == '1' ? '999.999.999-99' : '99.999.999/9999-99'} label={tipo_pessoa == '1' ? 'CPF' : 'CNPJ'} name="parceiro.cpf_cnpj" width={wMd} />
    );
  };

  const wMd = isMobile ? 'full' : "33%";
  const w2Md = isMobile ? 'full' : "50%";

  const eCnpj = dadosPessoais?.parceiro?.tipo_pessoa == '2'

  const forceSubmit = () => {
    document.getElementById("submit-form-dados-pessoais")?.click()
  }

  return (
    <Menu>
      <Navigation
        itens={[
          {
            nome: 'Início',
            rota: '/',
          },
          {
            nome: 'Perfil',
            rota: '/dados-pessoais',
          },
          {
            nome: 'Dados Pessoais',
            rota: '/dados-pessoais',
            ativo: true,
          },
        ]}
      />
      <MenuPerfil>
        <Loader isLoading={isLoading ?? false} />
        <Flex wrap="wrap" width="full">
          <MenuPerfilHeader nome="Dados Pessoais" />

          {typeof errors == "string" && <Error error={errors} />}

          {dadosPessoais && <Formik
            initialValues={dadosPessoais}
            enableReinitialize={true}
            onSubmit={(values, { setErrors }) => {
              if (!values.usuario.email) {
                setErrors({
                  usuario: {
                    email: 'Preencha um email válido!'
                  }
                })
                return;
              }

              if (returnOnlyNumber(values.parceiro.telefone).length < 10) {
                setErrors({
                  parceiro: {
                    telefone: 'Preencha corretamente o telefone.'
                  }
                })
                return;
              }

              console.log("perfil form", values);
              dispatch(perfilActions.saveDadosPessoaisRequest({
                ...values,
                parceiro: {
                  ...values.parceiro,
                  changed: true,
                  telefone: returnOnlyNumber(values.parceiro.telefone),
                },
                usuario: {
                  ...values.usuario,
                  changed: true,
                }
              }))
            }}
          >
            {({ values }) => (
              <Form>
                <Flex width="full" wrap="wrap">
                  <CpfCnpjComponent />
                  {eCnpj && <InputField disabled={eCnpj} height="42px" label="Razão Social" name="parceiro.razao_social" width={wMd} />}
                  <InputField disabled={eCnpj} height="42px" label={!eCnpj ? "Nome" : "Nome Fantasia"} name="parceiro.nome_fantasia" width={wMd} />
                  <InputField disabled={eCnpj && !!values.parceiro.rg_ie} height="42px" label={!eCnpj ? "RG" : "Inscrição Estadual"} name="parceiro.rg_ie" width={wMd} />
                  <InputField height="42px" label="E-mail" type="email" name="usuario.email" width={wMd} />
                  <InputField height="42px" label="Celular" type='tel' mask="(99)99999-9999" name="parceiro.telefone" width={wMd} />
                  {!eCnpj && <InputField height="42px" type={"date"} label="Data Nascimento" name="parceiro.data_nascimento" width={wMd} />}
                  <MenuPerfilEnderecos values={values} forceSubmit={forceSubmit} />
                  <ValidationComponent />
                  <Button
                    mt={6}
                    bg={sistemaConfig.siteCorPadrao}
                    color={"white"}
                    width={isMobile ? 'full' : '192px'}
                    borderRadius={50}
                    height="55px"
                    _hover={sistemaConfig.siteCorPadrao}
                    fontSize="20px"
                    type="submit"
                    isLoading={isSaving}
                    id="submit-form-dados-pessoais"
                  >
                    Salvar
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>}


          <MenuPerfilHeader showBack={false} nome="Redefinir Senha" />

          {typeof errors == "string" && <Error error={errors} />}
          <Flex width="full" wrap="wrap">

            <Formik
              initialValues={{
                old_password: "",
                password: "",
                password_confirmation: "",
              }}
              enableReinitialize={true}
              onSubmit={(values, { setErrors }) => {

                let addErrors = {};
                if (values.password !== values.password_confirmation) {
                  addErrors = {
                    password_confirmation: 'A confirmação da nova senha não confere.'
                  }
                }

                const errors = {
                  ...validateForm({
                    old_password: 'required',
                    password: 'required',
                    password_confirmation: 'required',
                  }, values),
                  ...addErrors,
                };

                if (Object.keys(errors).length > 0) {
                  setErrors(errors);
                  return;
                }

                dispatch(perfilActions.saveRedefinirSenhaRequest(values));
              }}
            >
              {({ values }) => (
                <Form>
                  <Flex width="full" wrap="wrap">
                    <InputField type={"password"} height="42px" label="Senha atual" name="old_password" width={"100%"} />

                    <Flex width={"full"}>
                      <Divider mt={4} width={"full"} height="2px" bg="#D9D9D9" flex="1" />
                    </Flex>

                    <InputField type={"password"} height="42px" label="Digite a nova senha" name="password" width={w2Md} />
                    <InputField type={"password"} height="42px" label="Confirme a nova senha" name="password_confirmation" width={w2Md} />

                    <ValidationComponent />

                    <Button
                      mt={6}
                      bg={sistemaConfig.siteCorPadrao}
                      color={"white"}
                      width={isMobile ? 'full' : '192px'}
                      borderRadius={50}
                      height="55px"
                      _hover={sistemaConfig.siteCorPadrao}
                      fontSize="20px"
                      type="submit"
                      isLoading={isSaving}
                    >
                      Redefinir
                    </Button>
                  </Flex>
                </Form>)}
            </Formik>
          </Flex>
        </Flex>
      </MenuPerfil>
    </Menu>
  )
}

export default DadosPessoais;