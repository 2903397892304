import { Flex, useBreakpointValue } from "@chakra-ui/react"
import MenuPerfilItem from "./MenuPerfilItem";

const MenuPerfilItens = () => {
  const itens = [
    {
      nome: 'Dados pessoais',
      rota: '/dados-pessoais'
    },
    {
      nome: 'Pedidos',
      rota: '/meus-pedidos'
    },
    {
      nome: 'Sair',
      rota: '/sair'
    },
  ]
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex direction="column" mt={8} pr={isMobile ? 0 : 10}>
      {itens && itens.map(item => <MenuPerfilItem key={item.rota} nome={item.nome} rota={item.rota} />)}
    </Flex>
  )
}

export default MenuPerfilItens;