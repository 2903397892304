import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIsAuth } from "../../../hooks";
import { sistemaActions } from "../reducer";

const Sair = () => {
  const dispatch = useDispatch();

  useIsAuth();

  useEffect(() => {
    dispatch(sistemaActions.logout())
  }, [dispatch])

  return (
    <></>
  )
}

export default Sair;