import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ICadastro, ILogin, TCadastroLoginRes } from "./saga";
import { ApiFieldError, Usuario } from "./types";

type TInitialState = {
  auth_token?: string;
  user?: Usuario;
  error?: boolean;
  errors?: ApiFieldError;
  isLoading?: boolean;
  isMobile?: boolean;
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "sistema",
  initialState,
  reducers: {
    sistemaError(state, { payload }: PayloadAction<ApiFieldError>) {
      state.isLoading = false;
      state.error = true;
      state.errors = payload;
    },
    setSistemaErrorFalse(state) {
      state.error = false;
      state.errors = {} as ApiFieldError;
    },
    cadastroRequest(
      state, _: PayloadAction<ICadastro>
    ) {
      state.isLoading = true;
      state.error = false;
      state.errors = {} as ApiFieldError;
    },
    cadastroSuccess(state, { payload }: PayloadAction<TCadastroLoginRes>) {
      state.isLoading = false;
      state.user = payload.user;
      state.auth_token = payload.token;
      state.error = false;
      state.errors = {} as ApiFieldError;
    },
    loginRequest(
      state, _: PayloadAction<ILogin>
    ) {
      state.isLoading = true;
      state.error = false;
      state.errors = {} as ApiFieldError;
    },
    loginSuccess(state, { payload }: PayloadAction<TCadastroLoginRes>) {
      state.isLoading = false;
      state.user = payload.user;
      state.auth_token = payload.token;
      state.error = false;
      state.errors = {} as ApiFieldError;
    },
    setaIsMobile(state, { payload }: PayloadAction<boolean>) {
      state.isMobile = payload;
    },
    logout(state) {
      state.user = undefined;
      state.auth_token = "";
    },
  },
});

export const sistemaActions = reducer.actions;

export default reducer.reducer;
