import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { mensagemErro, mensagemSucesso } from "../../utils/toasts";
import { ApiFieldError } from "../sistema/types";
import { DadosPessoais, RedefinirSenha } from "./types";

type TInitialState = {
  dados_pessoais?: DadosPessoais;
  error?: boolean;
  errors?: ApiFieldError;
  isLoading?: boolean;
  isSaving?: boolean;
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "perfil",
  initialState,
  reducers: {
    perfilError(state, { payload }: PayloadAction<ApiFieldError>) {
      state.isLoading = false;
      state.isSaving = false;
      state.error = true;
      state.errors = payload;
      typeof payload === "string" && mensagemErro(payload);
    },
    setPerfilErrorFalse(state) {
      state.error = false;
      state.errors = {} as ApiFieldError;
    },
    dadosPessoaisRequest(state) {
      state.isLoading = true;
      state.error = false;
      state.errors = {} as ApiFieldError;
    },
    dadosPessoaisSuccess(state, { payload }: PayloadAction<DadosPessoais>) {
      state.isLoading = false;
      state.dados_pessoais = payload;
      state.error = false;
      state.errors = {} as ApiFieldError;
    },
    saveDadosPessoaisRequest(state, _: PayloadAction<DadosPessoais>) {
      state.isLoading = true;
      state.error = false;
      state.errors = {} as ApiFieldError;
    },
    saveDadosPessoaisRequestSuccess(
      state,
      { payload }: PayloadAction<DadosPessoais>
    ) {
      state.isLoading = false;
      state.dados_pessoais = payload;
      state.error = false;
      state.errors = {} as ApiFieldError;
      mensagemSucesso("Dados pessoais salvo com sucesso.");
    },
    saveRedefinirSenhaRequest(state, _: PayloadAction<RedefinirSenha>) {
      state.isLoading = true;
      state.error = false;
      state.errors = {} as ApiFieldError;
    },
    saveRedefinirSenhaRequestSuccess(state) {
      state.isLoading = false;
      state.error = false;
      state.errors = {} as ApiFieldError;
      mensagemSucesso("Senha redefinida com sucesso.");
    },
  },
});

export const perfilActions = reducer.actions;

export default reducer.reducer;
