import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { sistemaActions } from "./reducer";
import { Usuario } from "./types";
import { apiNoAuthCall } from "./api/apiCall";
import { formatError } from "../../utils/formatError";

export interface ICadastro {
  nome: string;
  email: string;
  celular: string;
  password: string;
};

export interface ILogin {
  email: string;
  password: string;
};

export interface TCadastroLoginRes {
  user: Usuario;
  token: string;
  expires_in: string;
};

function* cadastroWorker({
  payload,
}: PayloadAction<ICadastro>) {
  try {
    const res: AxiosResponse<TCadastroLoginRes> = yield call(apiNoAuthCall, {
      url: `/loja-virtual/criar-conta`,
      method: "post",
      data: payload,
    });
    yield put(sistemaActions.cadastroSuccess(res.data));
  } catch (error) {
    yield put(
      sistemaActions.sistemaError(formatError(error))
    );
  }
}

function* loginWorker({
  payload,
}: PayloadAction<ILogin>) {
  try {
    const res: AxiosResponse<TCadastroLoginRes> = yield call(apiNoAuthCall, {
      url: `/loja-virtual/entrar`,
      method: "post",
      data: payload,
    });
    yield put(sistemaActions.loginSuccess(res.data));
  } catch (error) {
    yield put(
      sistemaActions.sistemaError(formatError(error))
    );
  }
}

export function* sistemaSaga() {
  yield all([
    takeLatest("sistema/cadastroRequest", cadastroWorker),
    takeLatest("sistema/loginRequest", loginWorker),
  ]);
}
