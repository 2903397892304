import { Box, Button, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { ReactNode } from 'react';
import sistemaConfig from '../../../config/sistemaConfig';
import ListHeaderSecondary from './ListHeaderSecondary';

interface IProps {
  children: ReactNode,
  confirm: () => void;
  onOpenModal?: () => void;
}

const ConfirmationModal = ({ children, confirm, onOpenModal }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box onClick={() => typeof onOpenModal === 'function' ? onOpenModal() : onOpen()}>
        {children}
      </Box>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"sm"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={'Tem certeza?'} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button ml={2} mt={2} colorScheme='gray' onClick={onClose}>Cancelar</Button>
            <Button
              bg={sistemaConfig.siteCorPadrao}
              color={"white"}
              _hover={sistemaConfig.siteCorPadrao}
              onClick={() => { confirm(); onClose(); }} ml={2} mt={2} >Sim!</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


    </>
  )
}

export default ConfirmationModal;
