import { Flex, Tooltip } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom";
import sistemaConfig from "../../../config/sistemaConfig";
import FloatingBadge from "./FloatingBadge";

interface IProps {
  icon: any;
  rotaAtiva: Boolean;
  badgeNumber?: number | null;
  nome: string;
  isDesktop?: Boolean;
  routeTo?: string;
}

const MenuItem = ({ icon, rotaAtiva, badgeNumber = null, nome, isDesktop = true, routeTo = "" }: IProps) => {
  const navigate = useNavigate();

  return (
    <Tooltip label={nome} aria-label={nome}>
      <Flex onClick={() => {
        if (!routeTo) {
          return;
        }

        navigate(routeTo)
      }}>
        {!isDesktop && <Flex position="relative" direction="column" padding={rotaAtiva ? '0 0 8px 0' : '8px 0'}>
          {rotaAtiva && <span style={{ backgroundColor: sistemaConfig.siteCorPadrao, width: "100%", padding: '2px', borderRadius: '0 0 15px 15px', marginBottom: '4px' }}> </span>}
          <Flex cursor="pointer" _hover={{ backgroundColor: '#EFEDED' }} position="relative" bg={rotaAtiva ? '#EFEDED' : 'white'} borderRadius={10} padding={4} height="100%">
            {icon}
            {typeof badgeNumber == 'number' && <FloatingBadge number={badgeNumber} />}
          </Flex>
        </Flex>}
        {isDesktop && <Flex position="relative" direction="column" padding={rotaAtiva ? '8px 0 0 0' : '8px 0'}>
          <Flex cursor="pointer" _hover={{ backgroundColor: '#EFEDED' }} position="relative" bg={rotaAtiva ? '#EFEDED' : 'white'} borderRadius={10} padding={4} height="100%">
            {icon}
            {typeof badgeNumber == 'number' && <FloatingBadge number={badgeNumber} />}
          </Flex>
          {rotaAtiva && <span style={{ backgroundColor: sistemaConfig.siteCorPadrao, width: "100%", padding: '2px', borderRadius: '15px 15px 0 0', marginTop: '4px' }}> </span>}
        </Flex>}
      </Flex>
    </Tooltip>
  )
}

export default MenuItem;