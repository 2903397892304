import { all } from "redux-saga/effects";
import { perfilSaga } from "../perfil/saga";
import { sistemaSaga } from "../sistema/saga";

export function* mainSaga() {
  yield all([
    sistemaSaga(),
    perfilSaga(),
  ]);
}
