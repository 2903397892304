import { Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import sistemaConfig from "../../../config/sistemaConfig";
import { RootState } from "../../app/mainReducer";

interface IProps {
  nome: string;
  rota: string;
}

const isRotaAtiva = (rotas: string[], pathname: string) => {
  return rotas.some(function (rota) {
    return pathname.includes(rota);
  });
}

const MenuPerfilItem = ({ nome, rota }: IProps) => {
  const location = useLocation();
  const rotaAtiva = isRotaAtiva([rota], location.pathname)
  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);
  const navigate = useNavigate();

  const altura = isMobile ? '30px' : '60px';
  const fonte = isMobile ? '18px' : '20px';

  return (
    <Flex onClick={() => navigate(rota)} mb={1} borderRadius={10} cursor="pointer" bg={rotaAtiva ? '#EFEDED' : 'white'} _hover={{ backgroundColor: '#EFEDED' }} position="relative" height={altura}>
      <Flex
        _hover={{ backgroundColor: rotaAtiva ? sistemaConfig.siteCorPadrao : '' }}
        style={{
          backgroundColor: rotaAtiva ? sistemaConfig.siteCorPadrao : '',
        }}
        height={altura}
        width="6px"
        borderRadius="10px 0 0 10px"
      />
      <Flex as={rotaAtiva ? 'b' : 'div'} fontSize={fonte} alignItems="center" height={altura} color="#444444" pl={4}>
        {nome}
      </Flex>
    </Flex>
  )
}

export default MenuPerfilItem;