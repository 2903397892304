import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { mainSaga } from "./mainSaga";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import sistemaReducer from "../sistema/reducer";
import perfilReducer from "../perfil/reducer";

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();
const persistConfig = {
  key: "loja",
  storage,
};
const rootReducer = combineReducers({
  sistema: sistemaReducer,
  perfil: perfilReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middlewares: any = [sagaMiddleware];
if (process.env.NODE_ENV === "development") {
  middlewares = [...middlewares, logger];
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
});
export const persistor = persistStore(store);

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
