import { RadioGroup, RadioGroupProps } from "@chakra-ui/react";
import { useField } from "formik";
import React from "react";

type RadioFieldProps = RadioGroupProps & {
  name: string;
};

const InputRadio: React.FC<RadioFieldProps> = ({
  size: _,
  onChange,
  children,
  ...props
}) => {
  const [field, , { setValue }] = useField(props.name);
  return (
    <RadioGroup
      {...field}
      {...props}
      onChange={(val) => { setValue(val); typeof onChange === 'function' && onChange(val) }}
      id={field.name}
    >
      {children}
    </RadioGroup>
  );
};

export default InputRadio;
