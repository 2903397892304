import getEnv from "../utils/getEnv";

const storageFolder = getEnv('REACT_APP_STORAGE_FOLGER');

const storageConfig = {
  imagensProdutos: `${storageFolder}/imagens-produtos/`,
  logoCompleta: `${storageFolder}/logo.png`,
  logoPin: `${storageFolder}/logo-pin.svg`,

}

export default storageConfig;