import axios from "axios";
import { AxiosRequestConfig } from "axios";
import sistemaConfig from "../../../config/sistemaConfig";
import { store } from "../../app/mainReducer";

export const getToken = () => store.getState().sistema.auth_token;

export const apiAuthCall = (config: AxiosRequestConfig) =>
  axios({
    ...config,
    baseURL: sistemaConfig.apiUrl,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const apiNoAuthCall = (config: AxiosRequestConfig) =>
  axios({
    ...config,
    baseURL: sistemaConfig.apiUrl,
  });
